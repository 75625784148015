<template>
    <header id="header" class="media">
        <div class="pull-left h-logo">
            <router-link to="/" class="hidden-xs">
                <img src="img/logo.png" alt=".">
            </router-link>
            <div class="menu-collapse" data-ma-action="sidebar-open" data-ma-target="main-menu">
                <div class="mc-wrap">
                    <div class="mcw-line top palette-White bg"></div>
                    <div class="mcw-line center palette-White bg"></div>
                    <div class="mcw-line bottom palette-White bg"></div>
                </div>
            </div>
        </div>

        <ul class="pull-right h-menu">
            <li class="hm-search-trigger">
                <a href="#" data-ma-action="search-open">
                    <i class="hm-icon zmdi zmdi-search"></i>
                </a>
            </li>
            <li>
                <div class="m-r-20 c-white">
                    <div><strong>{{ $store.state.user.name }}</strong></div>
                    <div>{{ $store.state.user.email }}</div>
                </div>
            </li>
            <li class="dropdown hm-profile">
                
                <a data-toggle="dropdown" href="#">
                    <img src="img/placeholder.png" alt=".">
                </a>
                
                <ul class="dropdown-menu pull-right dm-icon">
                    <li>
                        <router-link to="/settings"><i class="zmdi zmdi-settings"></i>Settings</router-link>
                    </li>
                    <li><a @click="logout"><i class="zmdi zmdi-power"></i> Logout</a></li>
                </ul>
            </li>
        </ul>
        
        <div class="media-body h-search">
            <form class="p-relative" @submit.prevent="searchStaff">
                <input type="text" class="hs-input" placeholder="Search staff by name ... " v-model="search.name">
                <i v-if="search.name == ''" class="zmdi zmdi-search hs-reset"></i>
                <i v-if="showActions" @click="clearSearch" class="zmdi zmdi-arrow-left hs-reset"></i>
                <button class="btn btn-danger btn-icon btn-clear" type="button" @click="clearSearch" v-if="showActions">
                    <i class="zmdi zmdi-close"></i>
                </button>
            </form>
        </div>
        
    </header>
</template>

<script>
export default {
    data(){
        return {
            search: {
                name: ""
            }
        }
    },
    methods: {
        logout() {
            this.$swal
                .fire({
                    type: "warning",
                    title: "LOGOUT",
                    text: 'Are you sure you want to logout?',
                    showCancelButton: true,
                    focusConfirm: true,
                })
                .then(response => {
                    if (response.value) {
                        this.$store.dispatch('logout').then(() => {
                            this.$router.push('/login')
                        })
                    }
                })
        },
        searchStaff(){
            if (this.search.name != '') {
                this.$store.dispatch('loadStaff', this.search.name)
                if (!this.$route.path.includes('/search/')) {
                    this.$router.push("/search")
                }
            }
        },
        clearSearch(){
            this.$store.commit('clear_search')
            this.search.name = ""
        }
    },
    computed: {
        showActions(){
            return this.search.name != ''
        }
    }
}
</script>
